import React from "react"
import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"
import AussichtLeichteSprache from "../../../content/shared/aussicht-leichte-sprache"

const LeichteSpracheAussicht = () => {
  return (
    <Layout backdrop="leichte-sprache-aussicht">
      <Seo
        title="Hölderlins Gedicht ›Aussicht‹"
        description="In seinem Zimmer im Turm hat Hölderlin eine tolle Aus∙sicht.  Das Zimmer hat 5 Fenster.  Durch die Fenster sieht Hölderlin den Fluss »Neckar«.  Und er sieht die grüne Land∙schaft auf der anderen Seite vom Fluss.  Über diese Aus∙sicht schreibt Hölderlin auch Gedichte."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Aussicht",
                link: "/leichte-sprache/ueber-hoelderlin/aussicht",
              },
            ]}
          />
          <PageTitle>Hölderlins Gedicht ›Aussicht‹</PageTitle>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/10-aussicht" />
          </Constrain>
          <Paragraph dropcap={true}>
            In seinem Zimmer im Turm <br />
            hat Hölderlin eine tolle Aus∙sicht. <br />
            Das Zimmer hat 5 Fenster. <br />
            Durch die Fenster sieht Hölderlin <br />
            den Fluss »Neckar«. <br />
            Und er sieht <br />
            die grüne Land∙schaft <br />
            auf der anderen Seite vom Fluss.
          </Paragraph>
          <Paragraph>
            Über diese Aus∙sicht schreibt Hölderlin
            <br />
            auch Gedichte.
            <br />
            Eines der Gedichte
            <br />
            sehen sie hier
            <br />
            in Hölderlins Hand∙schrift.
            <br />
            So eine Gedicht-Hand∙schrift nennt man:
            <br />
            Manuskript.
          </Paragraph>
        </Stack>

        <Constrain>
          <AussichtLeichteSprache />
        </Constrain>

        <LeichteSpracheNext variant="orte" />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheAussicht
